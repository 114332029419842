import * as Sentry from '@sentry/react';
import { createObjectURL } from 'blob-util';
import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as uuid from 'uuid';
import loader from '../../assets/loader.gif';
import { useApiCall } from '../../containers/useApiCall';
import { resolveHeaders } from '../../utils';
import stl from './DownloadButton.module.scss';

export interface DownloadButtonProps {
  downloadUri: string;
  className?: string;
  bsStyle?: string;
  component?: any;
  children?: React.ReactNode;
  showLabelXs?: boolean;
}

const DownloadButton = ({
  downloadUri,
  className,
  bsStyle,
  component,
  showLabelXs,
}: DownloadButtonProps) => {
  const [status, setStatus] = useState<
    null | 'downloading' | 'failure' | 'success'
  >(null);
  const accessToken = useSelector<any, string>(s => s.user.accessToken);
  const handleDownload = async () => {
    setStatus('downloading');
    try {
      const jobId = uuid.v4();
      const pollingUrl = new URL(downloadUri, window.location as any);
      pollingUrl.searchParams.append('jobId', jobId);

      let blob: Blob | null = null;
      do {
        const res = await fetch(pollingUrl, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (res.status === 200) {
          blob = await res.blob();
          break;
        }
        if (res.status !== 504) {
          throw new Error(
            `Unexpected result when downloading report (status: ${
              res.status
            }, body: ${await res.text()})`,
          );
        }
      } while (true);

      const element = document.createElement('a');
      const url = createObjectURL(blob);
      element.setAttribute('href', url);
      element.setAttribute('download', 'report.pdf');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      setStatus('success');
    } catch (e) {
      console.log(e, downloadUri);
      Sentry.captureException(e);
      setStatus('failure');
    }
  };

  const style = status === 'failure' ? 'danger' : 'primary';
  const Component: any = component || Button;

  return (
    <FormattedMessage id="PAPER.NAV.DOWNLOAD_BUTTON" defaultMessage="Download">
      {msg => (
        <Component
          bsStyle={style}
          className={classNames(className, stl[status || ''])}
          disabled={status === 'downloading'}
          onClick={handleDownload}
          title={msg.toString()}
        >
          {status === 'downloading' ? (
            <img alt="progress indicator" src={loader} />
          ) : status === 'failure' ? (
            <i className="fa fa-times" />
          ) : status === 'success' ? (
            <i className="fa fa-check" />
          ) : (
            <i className="fa fa-download" />
          )}{' '}
          <span className={classNames(!showLabelXs && 'hidden-xs')}>{msg}</span>
        </Component>
      )}
    </FormattedMessage>
  );
};

export default DownloadButton;
