import * as R from 'ramda';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'wouter';
import { useSearch } from 'wouter/use-location';
import { getTagAuthEnabled } from '../../getClientIdentifier';
import useNFCReader from '../../hooks/useNFCReacter';
import {
  AuthError,
  loginWithTag,
  signIn,
  useIsSignedIn,
} from '../../modules/user';
import { useSyncService } from '../../services/ServiceProvider';
import DevelopmentOnly from '../DevelopmentOnly';
import Json from '../Json';
import Page from '../Page';
import PinCodeEntry, { PinCodeEntryRef } from '../PinCodeModal';
import { NFCSpinner } from '../Spinner';
import styles from './Login.module.scss';
import msg from './msg';

interface LoginProps {
  username?: string;
  show?: boolean;
}

const responseErrorView = R.view(R.lensPath(['payload', 'response', 'error']));

const LoginComponent: React.FC<LoginProps> = ({ username }) => {
  const pinCodeEntryRef = React.useRef<PinCodeEntryRef>(null);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [, go] = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const tagAuthEnabled = getTagAuthEnabled();
  const syncService = useSyncService();
  const search = useSearch();

  const searchParams = new URLSearchParams(search);
  const forcePasswordAuth = searchParams.get('force-password-auth') || '0';
  const redirectTarget = searchParams.get('r') || '/';

  const redirect = useCallback(() => {
    go(redirectTarget);
  }, [go, redirectTarget]);

  const handleTagIdRead = React.useCallback(async (tagId: string) => {
    try {
      try {
        await dispatch(loginWithTag(tagId));
      } catch (error) {
        console.log({ error });

        if (error instanceof AuthError) {
          if (error.reason === 'PIN_REQUIRED') {
            const pin = await pinCodeEntryRef.current!.getPinCode();
            await dispatch(loginWithTag(tagId, pin));
          } else if (error.reason === 'TAG_DISABLED') {
            // offer activation
            if (
              window.confirm(
                'Je kunt nog niet inloggen met deze tag.\n Wil je dit nu aanvragen?',
              )
            ) {
              // make user login and redirect them to the request page
              go('/login?r=/request-tag-authentication&force-password-auth=1');
            }
          }
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  }, []);

  useEffect(() => console.log({ handleTagIdRead }), [handleTagIdRead]);
  useEffect(() => console.log({ tagAuthEnabled }), [tagAuthEnabled]);
  useEffect(() => console.log({ forcePasswordAuth }), [forcePasswordAuth]);

  const { isReading, error: nfcError } = useNFCReader(
    handleTagIdRead,
    tagAuthEnabled && forcePasswordAuth === '0',
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const form = event.currentTarget;
      const password = (form.elements.namedItem('password') as HTMLInputElement)
        .value;
      const formUsername = (form.elements.namedItem(
        'username',
      ) as HTMLInputElement).value;

      const finalUsername = username || formUsername;

      if (!finalUsername || !password) return;

      setIsSubmitting(true);
      setPasswordVisible(false);
      setError(null);

      const res = (await dispatch(signIn(finalUsername, password))) as any;

      setIsSubmitting(false);

      if (res.error) {
        const passwordElement = form.elements.namedItem(
          'password',
        ) as HTMLInputElement;
        passwordElement.value = '';
        passwordElement.focus();
        setError(responseErrorView(res) || 'Something went wrong');
      } else {
        syncService.update(true);

        redirect();
      }
    },
    [dispatch, redirect, syncService, username],
  );

  const togglePasswordVisible = (event: React.MouseEvent) => {
    event.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  const getError = () => {
    if (error) {
      return (
        <Alert bsStyle="danger" onAbort={() => setError(null)}>
          {error}
        </Alert>
      );
    }
    return null;
  };

  return (
    <form className="form-signin" onSubmit={handleSubmit}>
      {tagAuthEnabled && isReading && <NFCSpinner />}

      <h2 className="form-signin-heading">
        <FormattedMessage {...msg.loginTitle} />
      </h2>
      {getError()}
      <input
        tabIndex={1}
        className="form-control"
        placeholder="Username"
        name="username"
        disabled={!!username}
        type="text"
        autoCorrect="off"
        autoCapitalize="none"
        defaultValue={username || ''}
      />
      <div className={styles.passwordWrapper}>
        <button
          type="button"
          tabIndex={3}
          className={styles.toggleVisibility}
          onClick={togglePasswordVisible}
        >
          {!passwordVisible ? (
            <i className="fa fa-eye" />
          ) : (
            <i className="fa fa-eye-slash" />
          )}
        </button>
        <input
          className="form-control"
          tabIndex={2}
          placeholder="Password"
          name="password"
          type={passwordVisible ? 'text' : 'password'}
        />
      </div>
      <input
        tabIndex={4}
        className="btn btn-lg btn-primary btn-block"
        type="submit"
        value={intl.formatMessage(msg.signIn)}
        disabled={isSubmitting}
      />
      <PinCodeEntry ref={pinCodeEntryRef} />
      <DevelopmentOnly>
        <button onClick={() => handleTagIdRead('ab:12:34')}>
          scan tag ab:12:34
        </button>
        <button onClick={() => handleTagIdRead('zy:98:76')}>
          scan tag zy:98:76
        </button>
        <Json>
          {{
            tagAuthEnabled,
            redirectTarget,
            forcePasswordAuth,
            nfc: {
              isReading,
              nfcError,
            },
          }}
        </Json>
      </DevelopmentOnly>
    </form>
  );
};

// Login Page
export const LoginPage: React.FC = () => {
  const user = useIsSignedIn();
  const [, go] = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user) {
      go('/');
    }
  }, [user, go]);

  return (
    <Page>
      <LoginComponent />
      <Link to="/forgot" style={{ width: '100%', display: 'block' }}>
        <FormattedMessage {...msg.forgot}>
          {txt => <button className="btn btn-link btn-block">{txt}</button>}
        </FormattedMessage>
      </Link>
    </Page>
  );
};

export const LoginDialog: React.FC<LoginProps> = ({ show }) => {
  return (
    <Modal show={show} onHide={() => {}}>
      <Modal.Body>
        <LoginComponent />
      </Modal.Body>
    </Modal>
  );
};

export default LoginPage;
