import jwtDecode from 'jwt-decode';
import md5 from 'md5';

/**
 * Storage of user tag-auth refresh-tokens,addressable by tag-id
 *
 * This allows pin-less authentication for multiple users on a single device
 *
 * NOTE: tag-ids are internally hashed for additional security
 * NOTE: this store has been externalized from the persisted redux store because this must be persisted beyond the lifetime of the user-session
 */
interface TokenEntry {
  token: string;
  hashedTagId: string;
}
interface StoreState {
  tokens: TokenEntry[];
  salt: string;
}
export default class TagRefreshTokenStore {
  private STORAGE_KEY = 'qa_tag_auth_store';

  private generateSalt(): string {
    // Generate random bytes using Web Crypto API
    const randomBytes = crypto.getRandomValues(new Uint8Array(16));

    // Convert to hex string
    const hexString = Array.from(randomBytes)
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');

    return hexString;
  }

  private hashId(uuid: string, salt: string): string {
    return md5(uuid + salt); // todo: upgrade to web crypto api & stronger algorithm
  }

  private state: StoreState;
  constructor() {
    const jsonState = localStorage.getItem(this.STORAGE_KEY);
    if (jsonState) {
      this.state = JSON.parse(jsonState);
    } else {
      this.state = { tokens: [], salt: this.generateSalt() };
    }
  }

  private saveToLocalStorage() {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.state));
  }

  public setToken(uuid: string, token: string) {
    const hashedTagId = this.hashId(uuid, this.state!.salt);

    // Remove existing token with same uuid if it exists
    const filteredTokens = this.state.tokens.filter(
      t => t.hashedTagId !== hashedTagId,
    );

    this.state.tokens = [
      ...filteredTokens,
      {
        token,
        hashedTagId,
      },
    ];
    this.saveToLocalStorage();
  }

  public getToken(uuid: string): string | null {
    const hashedTagId = this.hashId(uuid, this.state.salt);
    let tokenEntry =
      this.state.tokens.find(t => t.hashedTagId === hashedTagId) || null;
    let token = tokenEntry ? tokenEntry.token : null;
    if (token) {
      try {
        // Verify token validity
        const { exp } = jwtDecode(token) as any; // TODO: upgrade jwt-decode
        if (!exp || exp * 1000 < Date.now()) {
          // Token expired, throw  causing it to be removed
          throw new Error('expired');
        }
      } catch (error) {
        // Invalid token, remove it
        this.state.tokens = this.state.tokens.filter(
          t => t.hashedTagId !== hashedTagId,
        );
        token = null;
        this.saveToLocalStorage();
      }
    }

    return token;
  }
}
