import React, { ReactElement, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

const DevelopmentOnly = ({ children }: Props): ReactElement | null => {
  return null;
  return process.env.NODE_ENV !== 'production' ? <>{children}</> : null;
};

export default DevelopmentOnly;
