export default interface Account {
  recnum: number;
  loginname: string;
  name: string;
  email: string;
  internal: boolean;
  permissions: number;
  activated: boolean;
  password_strength: number | null;
  password_date: string | null;
  legacy_password: boolean;
  last_successful_login: string | null;
  auth_attempts: number;
  department: string;
  contact: string;
  relat: string;
  note: string;
  authentication_tag_enabled: boolean;
  requested_authentication_tag_date: string | null;
  requested_authentication_tag: boolean;
  clientInfo?: {
    client_id: string;
    useragent: string;
    success: boolean;
    meta: any;
    version: string;
    created: string | Date;
    last_contact: string | Date;
  }[];
}
export interface AccountPatch extends Account {
  force_password_reset: boolean;
  force_reactivation: boolean;
  send_invitation: boolean;
  reactivation_reason: string;
  action_url: string;
  bcc: string;
  enable_requested_authentication_tag_id: boolean;
  set_authentication_tag_id: null;
}

export const loginnameValidator = /^[a-z0-9][a-z0-9_.]+[a-z0-9]+$/;
