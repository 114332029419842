import { defineMessages } from 'react-intl';

export default defineMessages({
  loginTitle: {
    id: 'LOGIN.SIGN_IN_TITLE',
    defaultMessage: 'Sign in',
    description: 'Instruction sentence above login form',
  },
  signIn: {
    id: 'LOGIN.SIGN_IN',
    defaultMessage: 'Sign in',
    description: 'Label of sign-in button on login form',
  },
  forgot: {
    id: 'LOGIN.FORGOT',
    defaultMessage: 'Forgot username/password?',
    description: 'label of password or username button',
  },
});
