import jwtDecode from 'jwt-decode';
import { Store } from 'redux';
import Types from 'Types';
import {
    refreshAccessToken, refreshRefreshToken, setAccessTokenInValid, UserState
} from '../modules/user';

function jwtTTL(jwt: string) {
  const { exp } = jwtDecode(jwt) as any;
  return exp * 1000 - Date.now();
}

/**
 * This service maintains tokens and related state in the store
 * obtains new access tokens when the app loads
 * obtains new access tokens periodically
 * updates store when an access tokens expires and user interaction is required
 */
export class TokenService {
  private readonly TTL_MARGIN = 60e3;

  constructor(private store: Store<Types.RootState, Types.RootAction>) {
    this.store.subscribe(this.handleStoreChange);
    this.handleStoreChange();
    window.setInterval(this.refreshConventionalRefreshToken, 10 * 60e3);
  }
  refreshConventionalRefreshToken = () => {
    const { user } = this.store.getState();
    if (!user || !user.refreshToken) {
      // no session / no conventional refresh token
      return;
    }

    if (jwtTTL(user.refreshToken) < 5 * 24 * 3600e3) {
      // refresh access-token
      this.store.dispatch(refreshRefreshToken() as any);
    }
  };

  private prevUser: UserState = null;
  handleStoreChange = () => {
    const { user } = this.store.getState();
    /*  fixme: don't manually compare for changes and assume te reader/mainer understands that these changes are made immutably
     *   => use a helper function which makes this explicit
     *      this should also make it more readable as a beneficial side-effect
     */
    if (user !== this.prevUser) {
      if (user?.accessToken !== this.prevUser?.accessToken) {
        clearTimeout(this.accessTokenExpiredTimeoutId);
        if (user?.accessToken) {
          const nearlyExpiredTime = jwtTTL(user.accessToken) - this.TTL_MARGIN;
          console.log(
            'register handleAccessTokenNearlyExpired in ',
            nearlyExpiredTime,
          );

          this.accessTokenExpiredTimeoutId = window.setTimeout(
            this.handleAccessTokenNearlyExpired,
            nearlyExpiredTime,
          );
        }
      }

      this.prevUser = user;
    }
  };

  private accessTokenExpiredTimeoutId: number = 0;
  private handleAccessTokenNearlyExpired = () => {
    const { user } = this.store.getState();
    if (!user) return;

    if (user.refreshToken) {
      // conventional accessToken (no tag auth)
      this.store.dispatch(refreshAccessToken() as any);
    } else {
      // access token is a tag-auth token
      this.store.dispatch(setAccessTokenInValid() as any);
    }
  };
}
