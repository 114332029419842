/**
 * This module manages the user profile of the authenticated (current) user
 */

import { decode, PermissionsObject } from '@certhon/cloud-permissions/lib';
import { PartialUserSettings } from '@certhon/user-settings/lib';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import reduce from 'reduce-reducers';
import { AnyAction } from 'redux';
import { CALL_API } from 'redux-api-middleware';
import Types from 'Types';
import { parseDateOrNull } from '../common/logic/misc';
import { DTO } from '../common/logic/types';
import { getClientIdentifier } from '../getClientIdentifier';
import Department from '../models/Department';
import { User } from '../models/User';
import TagRefreshTokenStore from '../stores/TagRefreshTokenStore';
import { resolveHeaders, resolveUrl } from '../utils';
import { verifyResponse } from '../utils/verifyResponse';

const tagRefreshTokenStore = new TagRefreshTokenStore();
const clientId = getClientIdentifier();

export interface CurrentUser extends User {
  permissions: PermissionsObject;
  internal: boolean;
  department: Department | null;
  accessToken: string;
  email: string;
  language: string;
  settings: PartialUserSettings;
  tag_auth_status: 'enabled' | 'requested' | 'disabled';
  requested_authentication_tag_date: Date | null;
}

type CurrentUserDTO = DTO<Omit<CurrentUser, 'permissions'>> & {
  permissions: number;
};

function parseCurrentUserDTO(dto: CurrentUserDTO): CurrentUser {
  return {
    ...dto,
    permissions: decode(dto.permissions),
    requested_authentication_tag_date: parseDateOrNull(
      dto.requested_authentication_tag_date,
    ),
  };
}

export interface RequestSignInAction {
  type: 'REQUEST_AUTHENTICATE';
}
export interface SuccessSignInAction {
  type: 'SUCCESS_AUTHENTICATE';
  payload: { refreshToken: string; accessToken: string; user: CurrentUserDTO };
}
export interface FailureSignInAction {
  type: 'FAILURE_AUTHENTICATE';
  error: true;
}

/**
 * conventional loginname/password auth to obtain refresh token
 */
export function signIn(username: string, password: string) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/refresh-token'),
      body: JSON.stringify({ username, password }),
      method: 'POST',
      headers: resolveHeaders(),
      credentials: 'same-origin',
      types: [
        'REQUEST_AUTHENTICATE',
        'SUCCESS_AUTHENTICATE',
        'FAILURE_AUTHENTICATE',
      ],
    },
  };
}

export interface RequestRefreshTokenAction {
  type: 'REQUEST_REFRESH_TOKEN';
}
export interface SuccessRefreshTokenAction {
  type: 'SUCCESS_REFRESH_TOKEN';
  payload: { accessToken: string };
}
export interface FailureRefreshTokenAction {
  type: 'FAILURE_REFRESH_TOKEN';
  error: true;
}

/**
 * obtain access-Token token using conventionally obtained refresh-token
 */
export function refreshAccessToken() {
  return (dispatch: any, getState: any) => {
    const {
      user: { refreshToken },
    } = getState();
    return {
      [CALL_API]: {
        endpoint: resolveUrl('/access-token'),
        method: 'POST',
        headers: resolveHeaders({ Authorization: `Bearer ${refreshToken}` }),
        types: [
          'REQUEST_REFRESH_TOKEN',
          'SUCCESS_REFRESH_TOKEN',
          'FAILURE_REFRESH_TOKEN',
        ],
      },
    };
  };
}
/**
 * obtain fresh refresh-Token token using conventionally obtained refresh-token
 */
export function refreshRefreshToken() {
  return (dispatch: any, getState: any) => {
    const {
      user: { refreshToken },
    } = getState();
    return {
      [CALL_API]: {
        endpoint: resolveUrl('/refresh-token'),
        method: 'POST',
        headers: resolveHeaders({ Authorization: `Bearer ${refreshToken}` }),
        types: [
          'REQUEST_REFRESH_TOKEN',
          'SUCCESS_REFRESH_TOKEN',
          'FAILURE_REFRESH_TOKEN',
        ],
      },
    };
  };
}

export class AuthError extends Error {
  constructor(public reason: 'TAG_DISABLED' | 'PIN_REQUIRED') {
    super();
  }
}

/**
 * Obtain refresh- a/o access-token using tag auth
 */
export function loginWithTag(tagId: string, pin?: string) {
  return async (dispatch: any, getState: any) => {
    const token = tagRefreshTokenStore.getToken(tagId);
    if (!token && !pin) {
      if (await isTagEnabled(tagId)) {
        throw new AuthError('PIN_REQUIRED');
      } else {
        throw new AuthError('TAG_DISABLED');
      }
    }

    if (pin) {
      // obtain new refresh-token
      await fetch(resolveUrl('/refresh-token'), {
        method: 'POST',
        headers: resolveHeaders({ Authorization: `Bearer ${token}` })(),
        body: JSON.stringify({ clientId, tagId, pin }),
      })
        .then(verifyResponse)
        .then(res => res.json())
        .then(payload => dispatch({ type: 'SUCCESS_AUTHENTICATE', payload }));
    } else {
      // refresh the access token
      await fetch(resolveUrl('/access-token'), {
        method: 'POST',
        headers: resolveHeaders({ Authorization: `Bearer ${token}` })(),
        body: JSON.stringify({ tagId }),
      })
        .then(verifyResponse)
        .then(res => res.json())
        .then(payload => dispatch({ type: 'SUCCESS_REFRESH_TOKEN', payload }));
    }
  };
}

export interface RequestFetchUserAction {
  type: 'REQUEST_FETCH_USER';
}
export interface SuccessFetchUserAction {
  type: 'SUCCESS_FETCH_USER';
  payload: CurrentUserDTO;
}
export interface FailureFetchUserAction {
  type: 'FAILURE_FETCH_USER';
  error: true;
}

/** Query backend to see if tag is enabled for authentication */
async function isTagEnabled(tagId: string) {
  return (
    (await fetch(resolveUrl(`/tag-auth-status/${tagId}`), {
      headers: resolveHeaders()(),
    }).then(res => res.json())) === 'enabled'
  );
}

export function fetchUser(token?: string, noAuthorization = false): any {
  let headers = {};
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  if (noAuthorization) {
    headers = { Authorization: null };
  }
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/me'),
      method: 'GET',
      headers: resolveHeaders(headers),
      credentials: 'same-origin',
      types: ['REQUEST_FETCH_USER', 'SUCCESS_FETCH_USER', 'FAILURE_FETCH_USER'],
    },
  };
}

/** This is used to render a report using an accessToken */
export function fetchUserAndSetAccessToken(token: string): any {
  return async (dispatch: any, getState: any) => {
    await fetch(resolveUrl('/me'), {
      headers: resolveHeaders({ Authorization: `Bearer ${token}` })(),
    })
      .then(verifyResponse)
      .then(res => res.json())
      .then(payload =>
        dispatch({
          type: 'SUCCESS_ACTIVATE_USER',
          payload: { user: payload, accessToken: token },
        }),
      );
  };
}

export interface RequestUpdateUserAction {
  type: 'REQUEST_UPDATE_USER';
}
export interface SuccessUpdateUserAction {
  type: 'SUCCESS_UPDATE_USER';
  payload: CurrentUserDTO;
}
export interface FailureUpdateUserAction {
  type: 'FAILURE_UPDATE_USER';
  error: true;
}

export interface CurrentUserUpdateFields extends CurrentUser {
  email: string;
  actionUrl: string;
  password: string;
  pin: string;
}

export type CurrentUserUpdate = Partial<CurrentUserUpdateFields>;

export function updateUser(userUpdate: CurrentUserUpdate): any {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/me'),
      method: 'PATCH',
      body: JSON.stringify(userUpdate),
      headers: resolveHeaders(),
      types: [
        'REQUEST_UPDATE_USER',
        'SUCCESS_UPDATE_USER',
        'FAILURE_UPDATE_USER',
      ],
    },
  };
}

export interface RequestActivateUserAction {
  type: 'REQUEST_ACTIVATE_USER';
}
export interface SuccessActivateUserAction {
  type: 'SUCCESS_ACTIVATE_USER';
  payload: { refreshToken: string; accessToken: string; user: CurrentUserDTO };
}
export interface FailureActivateUserAction {
  type: 'FAILURE_ACTIVATE_USER';
  error: true;
}

export type ActivateUserData = {
  token: string;
  loginname: string;
  password: string;
};

export function activateUser(data: ActivateUserData) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/activate'),
      body: JSON.stringify(data),
      method: 'POST',
      headers: resolveHeaders(),
      types: [
        'REQUEST_ACTIVATE_USER',
        'SUCCESS_ACTIVATE_USER',
        'FAILURE_ACTIVATE_USER',
      ],
    },
  };
}

export interface RequestRecoverCredentials {
  type: 'REQUEST_RECOVER_CREDENTIALS';
}
export interface SuccessRecoverCredentials {
  type: 'SUCCESS_RECOVER_CREDENTIALS';
}
export interface FailureRecoverCredentials {
  type: 'FAILURE_RECOVER_CREDENTIALS';
}

export function recover(identifier: string) {
  // build action url
  const url = new URL(window.location.href);
  url.pathname = '/recover';
  const actionUrl = url.toString();

  return {
    [CALL_API]: {
      endpoint: resolveUrl('/request-recover-password'),
      body: JSON.stringify({ identifier, actionUrl }),
      method: 'POST',
      headers: resolveHeaders(),
      types: [
        'REQUEST_RECOVER_CREDENTIALS',
        'SUCCESS_RECOVER_CREDENTIALS',
        'FAILURE_RECOVER_CREDENTIALS',
      ],
    },
  };
}

export interface RequestRecoverPassword {
  type: 'REQUEST_RECOVER_PASSWORD';
}
export interface SuccessRecoverPassword {
  type: 'SUCCESS_RECOVER_PASSWORD';
}
export interface FailureRecoverPassword {
  type: 'FAILURE_RECOVER_PASSWORD';
  error: true;
}

export interface PasswordRecoveryData {
  token: string;
  password: string;
}

export function recoverPassword(data: PasswordRecoveryData) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/recover-password'),
      body: JSON.stringify(data),
      method: 'POST',
      headers: resolveHeaders(),
      types: [
        'REQUEST_RECOVER_PASSWORD',
        'SUCCESS_RECOVER_PASSWORD',
        'FAILURE_RECOVER_PASSWORD',
      ],
    },
  };
}

export interface SignOutAction {
  type: 'SIGN_OUT';
}
export const signOut = (): SignOutAction => ({ type: 'SIGN_OUT' });

export interface SetAccessTokenInValidAction {
  type: 'SET_ACCESS_TOKEN_IN_VALID';
}
export const setAccessTokenInValid = (): SetAccessTokenInValidAction => ({
  type: 'SET_ACCESS_TOKEN_IN_VALID',
});

export type UserState =
  | null
  | (CurrentUser & {
      /** @deprecated dont store decoded permission, decode on demand  */
      permissions: PermissionsObject;
      /** for conventional single-user auth (username/pass)
       *
       * note that in case of tag-auth, the refresh token is not stored here
       */
      refreshToken: null | string;
      accessToken: null | string;

      /**
       * Indicates user interaction is required to obtain a valid access-token for one of the following reasons:
       *  - tag-access-token has (nearly) expired in tag needs to be scanned
       *  - refresh token has expired and user needs to authenticate completely
       *  - server has rejected a seemingly valid token
       */
      invalidAccessToken: boolean;
    });

export type UserActions =
  | FailureActivateUserAction
  | FailureFetchUserAction
  | FailureRecoverPassword
  | FailureSignInAction
  | FailureRefreshTokenAction
  | FailureUpdateUserAction
  | RequestActivateUserAction
  | RequestFetchUserAction
  | RequestRecoverPassword
  | RequestUpdateUserAction
  | SignOutAction
  | SuccessActivateUserAction
  | SuccessFetchUserAction
  | SuccessRecoverPassword
  | SuccessSignInAction
  | SuccessUpdateUserAction
  | SuccessRefreshTokenAction
  | SetAccessTokenInValidAction;

/**
 * A reducing function that changes the `invalidAccessToken` field to true when
 * it determines the accessToken is invalid based on a `fuzzy` search in api-call actions
 */
function invalidateAccessTokenReducer(
  state: UserState,
  action: UserActions,
): UserState {
  const a = action as AnyAction;
  if (
    state &&
    /FAILURE_.*/.test(a.type) &&
    a.payload &&
    a.payload.name === 'ApiError' &&
    a.payload.status === 401 &&
    a.type !== 'FAILURE_AUTHENTICATE' &&
    a.payload.response &&
    a.payload.response.error === 'Invalid token'
  ) {
    return { ...state, invalidAccessToken: true };
  }
  return state;
}

const defaultState = {
  invalidAccessToken: false,
  accessToken: null,
  refreshToken: null,
  accessTokenInValid: false,
};
/**
 * Main user state reducer
 */
function userStateReducer(
  state: UserState = null,
  action: UserActions,
): UserState {
  if (
    action.type === 'SUCCESS_FETCH_USER' ||
    action.type === 'SUCCESS_UPDATE_USER'
  ) {
    return {
      ...(state || defaultState),
      ...parseCurrentUserDTO(action.payload),
    };
  } else if (action.type === 'SUCCESS_REFRESH_TOKEN') {
    if (!state) {
      throw new Error('Cant refresh when no user/session');
    }
    return {
      ...state,
      ...action.payload,
    };
  } else if (
    action.type === 'SUCCESS_AUTHENTICATE' ||
    action.type === 'SUCCESS_ACTIVATE_USER'
  ) {
    const { user, ...rest } = action.payload;
    Sentry.setUser({
      id: user.recnum,
    });
    return {
      ...(state || defaultState),
      ...rest,
      ...parseCurrentUserDTO(user),
    };
  } else if (
    action.type === 'SET_ACCESS_TOKEN_IN_VALID' ||
    action.type === 'FAILURE_REFRESH_TOKEN'
  ) {
    if (state) {
      return {
        ...state,
        invalidAccessToken: true,
      };
    }
    return state;
  } else if (
    action.type === 'SIGN_OUT' ||
    action.type === 'FAILURE_AUTHENTICATE'
  ) {
    Sentry.setUser(null);

    return null;
  } else if (
    action.type === 'FAILURE_FETCH_USER' ||
    action.type === 'FAILURE_RECOVER_PASSWORD' ||
    action.type === 'FAILURE_UPDATE_USER' ||
    action.type === 'REQUEST_FETCH_USER' ||
    action.type === 'REQUEST_RECOVER_PASSWORD' ||
    action.type === 'REQUEST_UPDATE_USER' ||
    action.type === 'SUCCESS_RECOVER_PASSWORD'
  ) {
    return state;
  }
  // softAssertNever(action);
  return state;
}

const reducer: (state: UserState, action: UserActions) => UserState = reduce(
  invalidateAccessTokenReducer,
  userStateReducer,
);

export default reducer;

export function useCurrentUser() {
  const user = useSelector<Types.RootState, CurrentUser | null>(
    s => s.user as any, // FIXME: s.user' type resolves to never ??
  );
  if (!user) {
    throw new Error('useUser called in unauthenticated context');
  }
  return user;
}

export function useIsInternal() {
  const user = useCurrentUser();
  return user.internal;
}

export function useIsSignedIn(): boolean {
  const user = useSelector<Types.RootState, CurrentUser | null>(
    s => s.user as any, // FIXME: s.user' type resolves to never ??
  );
  return !!user;
}
