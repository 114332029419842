import React from 'react';
import { getClientIdentifier } from '../../getClientIdentifier';
import useNFCReader from '../../hooks/useNFCReacter';
import { resolveHeaders, resolveUrl } from '../../utils';
import { verifyResponse } from '../../utils/verifyResponse';
import DevelopmentOnly from '../DevelopmentOnly';
import Json from '../Json';
import Page from '../Page';
import PinCodeEntry, { PinCodeEntryRef } from '../PinCodeModal';
import { NFCSpinner } from '../Spinner';

interface RequestTagAuthProps {}

const RequestTagAuth: React.FC<RequestTagAuthProps> = ({}) => {
  // FIXME: status opvragen adhv user/account
  const [status, setStatus] = React.useState<string | null>(null);
  const clientId = getClientIdentifier();
  const pinCodeEntryRef = React.useRef<PinCodeEntryRef>(null);
  const [error, setError] = React.useState<string | null>(null);

  // set up nfc reader
  const handleTagId = React.useCallback(async (tagId: string) => {
    setError(null);
    let status: string | null = null;
    try {
      status = await fetch(resolveUrl(`/tag-auth-status/${tagId}`), {
        headers: resolveHeaders()(),
        credentials: 'same-origin',
      })
        .then(verifyResponse)
        .then(res => res.json());
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Something went wrong');
    }
    if (status !== 'disabled') {
      setStatus(status);
      return;
    }
    const pin = await pinCodeEntryRef.current!.getPinCode(true);
    if (pin) {
      try {
        await fetch(resolveUrl('/me'), {
          method: 'PATCH',
          headers: resolveHeaders()(),
          credentials: 'same-origin',
          body: JSON.stringify({ requested_authentication_tag_id: tagId, pin }),
        }).then(verifyResponse);
        setStatus('requested');
      } catch (error) {
        setError(
          error instanceof Error ? error.message : 'Something went wrong',
        );
      }
    }
  }, []);
  const { isReading, error: nfcError } = useNFCReader(handleTagId, true);

  return (
    <Page title="Request Tag Auth">
      {!status && (
        <>
          Je kun tag-authenticatie als volgt aanvragen.
          <ol>
            <li>Scan je deurtag om te beginnen</li>
            <li>Kies een pincode</li>
            <li>Je verzoek wordt opgestuurd naar accountbeheerders</li>
            <li>Zodra je verzoek goedgekeurd is krijg je een email</li>
            <li>
              Je kunt je tag/pincode gebruiken om in te loggen op ondersteunde
              apparaten
            </li>
          </ol>
        </>
      )}
      {status === 'enabled' && (
        <>
          Tag-authenticatie is al ingeschakeld. Scan nogmaals om je oude tag te
          vervangen door een nieuwe
        </>
      )}
      {status === 'requested' && (
        <>
          Er staat een verzoek uit voor tag-authenticatie . Scan nogmaals om een
          nieuw verzoek in te dienen.
        </>
      )}
      {isReading && <NFCSpinner />}
      <DevelopmentOnly>
        <button onClick={() => handleTagId('ab:12:34')}>
          scan tag ab:12:34
        </button>
        <button onClick={() => handleTagId('zy:98:76')}>
          scan tag zy:98:76
        </button>
        <Json>{{ status }}</Json>
      </DevelopmentOnly>
      <PinCodeEntry ref={pinCodeEntryRef} />
    </Page>
  );
};

export default RequestTagAuth;
