import * as React from 'react';
import { useState } from 'react';

const AnimatedCircle: React.FC<{
  center: number[];
  size: number;
  timeFactor: number;
  prefix: any;
}> = ({ prefix, center, size = 90, timeFactor = 1 }) => {
  const cX = center[0];
  const cY = center[1];
  const radius = size / 2;
  return (
    <g>
      <path
        d={`
          M${cX - radius}, ${cY}
          a ${radius},${radius} 0 1, 0 ${2 * radius}, 0
          a ${radius},${radius} 0 1, 0 ${-2 * radius}, 0
        `}
        stroke={`url(#${prefix}-gradient)`}
        strokeOpacity="0.7"
        strokeWidth="1"
        className=""
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from={`${timeFactor > 0 ? 0 : 360} ${cX} ${cY}`}
          to={`${timeFactor > 0 ? 360 : 0} ${cX} ${cY}`}
          dur={`${Math.abs(timeFactor)}s`}
          repeatCount="indefinite"
        />
      </path>
    </g>
  );
};

interface SpinnerProps {
  numFrequencies?: number;
  numCircles?: number;
  circleOffset?: number;
  color?: string;
  nfc?: boolean;
}

const Spinner: React.FC<SpinnerProps> = props => {
  const [numFrequencies] = useState(
    props.numFrequencies || 1 + Math.round(Math.random() * 4),
  );
  const [numCircles] = useState(
    props.numCircles || 4 + Math.round(Math.random() * 12),
  );
  const [circleOffset] = useState(
    props.circleOffset || 1 / (3 + Math.round(Math.random() * 8)),
  );
  const [prefix] = useState(Math.round(Math.random() * 1000));
  const color = props.color || 'black';

  const SIZE = 100;
  const PADDING = 10;
  const OFFSET = SIZE * circleOffset;
  const CIRCLE_SIZE = SIZE - 2 * OFFSET;
  const CENTER = SIZE / 2 + PADDING;

  const circles = [];

  for (let i = 0; i < numCircles; i++) {
    const angle = ((2 * Math.PI) / numCircles) * i;
    const center = [
      CENTER + Math.cos(angle) * OFFSET,
      CENTER + Math.sin(angle) * OFFSET,
    ];
    circles.push(
      <AnimatedCircle
        key={i}
        center={center}
        size={CIRCLE_SIZE}
        timeFactor={1.5 + (i % numFrequencies)}
        prefix={prefix}
      />,
    );
  }

  return (
    <div className="spinner">
      <svg
        width={SIZE + 2 * PADDING}
        height={SIZE + 2 * PADDING}
        viewBox={`0 0 ${SIZE + 2 * PADDING} ${SIZE + 2 * PADDING}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>{circles}</g>
        {props.nfc && (
          <g transform="translate(48,48)">
            <path
              stroke={color}
              d="M16.3 19.5002C17.4 17.2002 18 14.7002 18 12.0002C18 9.30024 17.4 6.70024 16.3 4.50024M12.7 17.8003C13.5 16.0003 14 14.0003 14 12.0003C14 10.0003 13.5 7.90034 12.7 6.10034M9.1001 16.1001C9.7001 14.8001 10.0001 13.4001 10.0001 12.0001C10.0001 10.6001 9.7001 9.10015 9.1001 7.90015M5.5 14.3003C5.8 13.6003 6 12.8003 6 12.0003C6 11.2003 5.8 10.3003 5.5 9.60034"
              // stroke="#000 000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        )}
        <defs>
          <linearGradient
            id={`${prefix}-gradient`}
            x1={'0%'}
            x2={'100%'}
            y1={'50%'}
            y2={'50%'}
            gradientUnits="objectBoundingBox"
          >
            <stop
              offset="0"
              stopColor={color}
              color={color}
              stopOpacity="0.98"
            ></stop>
            <stop
              offset="0.13"
              stopColor={color}
              color={color}
              stopOpacity="0.92"
            ></stop>
            <stop
              offset="0.71"
              stopColor={color}
              color={color}
              stopOpacity="0.6"
            ></stop>
            <stop
              offset="1"
              stopColor={color}
              color={color}
              stopOpacity="0"
            ></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export const NFCSpinner: React.FC = () => (
  <Spinner
    // color="lightblue"
    numCircles={10}
    numFrequencies={3}
    circleOffset={0.12}
    nfc
  />
);

export default Spinner;
