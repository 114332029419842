import { decode, encode } from '@certhon/cloud-permissions';
import fromPairs from 'ramda/es/fromPairs';
import * as React from 'react';
import {
    Alert,
    Button,
    Checkbox,
    ControlLabel,
    DropdownButton,
    FormControl,
    FormGroup,
    HelpBlock,
    MenuItem
} from 'react-bootstrap';
import { useStore } from 'react-redux';
import { UAParser } from 'ua-parser-js';
import loader from '../../assets/loader.gif';
import Account, {
    AccountPatch,
    loginnameValidator
} from '../../models/Account';
import FormattedRelative from '../FormattedRelative';
import Page from '../Page';
import Spinner from '../Spinner';

const PERMISSIONS: string[] = Object.keys(decode(0));
const permissionIsExternal = decode(128);

const ReadableUseragentString: React.FC<{
  children: string;
}> = ({ children }) => {
  const result = UAParser(children);
  const { browser, os } = result;
  return (
    <span title={JSON.stringify(result, null, 2)}>
      {browser.name} on {os.name}
    </span>
  );
};

interface Props {
  account: Account | null;
  patchSuccess: Account | null;
  err: Error | null;
  patchAccount: (
    account: Partial<AccountPatch> & { recnum: number },
  ) => Promise<any>;
  patchErr: Error | null;
}

function FieldGroup(data: any) {
  const { id, label, help, ...props } = data;
  return (
    <FormGroup controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

const AccountDetailsComponent: React.FC<Props> = props => {
  const { account, err, patchAccount, patchErr, patchSuccess } = props;
  let content = <Spinner></Spinner>;
  const [submitBusy, setSubmitBusy] = React.useState(false);
  const store = useStore();

  let activatedRef = React.useRef<HTMLInputElement>();
  let nameRef = React.useRef<HTMLInputElement>();
  let noteRef = React.useRef<HTMLTextAreaElement>();
  // the number of permissions wont ever change in the app lifecycle
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let permissionRefs = PERMISSIONS.map(() => React.useRef<HTMLInputElement>());

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (account) {
        const permissions = encode(
          fromPairs(
            PERMISSIONS.map((perm, idx) => [
              perm,
              permissionRefs[idx]?.current?.checked || false,
            ]),
          ),
        );
        setSubmitBusy(true);
        patchAccount({
          recnum: account?.recnum,
          activated: activatedRef.current?.checked,
          name: nameRef.current?.value.trim(),
          note: noteRef.current?.value.trim(),
          permissions,
        }).finally(() => setSubmitBusy(false));
      }
    },
    [account, patchAccount, permissionRefs, nameRef, noteRef, activatedRef],
  );
  const handleResetAuthAttempts = React.useCallback(
    (event: React.MouseEvent<Button>) => {
      if (account) {
        setSubmitBusy(true);
        patchAccount({
          recnum: account?.recnum,
          auth_attempts: 0,
        }).finally(() => setSubmitBusy(false));
      }
    },
    [account, patchAccount],
  );

  const handleTagAuthRequest = React.useCallback(
    (enable_requested_authentication_tag_id: boolean) => {
      setSubmitBusy(true);
      patchAccount({
        recnum: account!.recnum,
        enable_requested_authentication_tag_id,
      }).finally(() => setSubmitBusy(false));
    },
    [account, patchAccount],
  );

  const handleSendInvitation = React.useCallback(
    (event: React.MouseEvent<Button>) => {
      if (
        account &&
        !account.loginname.trim() &&
        // eslint-disable-next-line no-restricted-globals
        confirm('Are you sure you wish to send an invitation email')
      ) {
        setSubmitBusy(true);
        patchAccount({
          recnum: account?.recnum,
          send_invitation: true,
          action_url: window.location.origin + '/activate',
          bcc: store.getState().user.email,
        }).finally(() => setSubmitBusy(false));
      }
    },
    [account, patchAccount, setSubmitBusy, store],
  );

  const handleForceReactivation = React.useCallback(
    (event: React.MouseEvent<Button>) => {
      if (
        account &&
        // eslint-disable-next-line no-restricted-globals
        confirm(
          'Are you sure you want to deactivate this account and send a reactivation email',
        )
      ) {
        const reactivation_reason =
          prompt('Optionally provide a reason for reactivation') || undefined;

        setSubmitBusy(true);
        patchAccount({
          recnum: account?.recnum,
          force_reactivation: true,
          reactivation_reason,
          action_url: window.location.origin + '/activate',
          bcc: store.getState().user.email,
        }).finally(() => {
          setSubmitBusy(false);
        });
      }
    },
    [account, patchAccount, store, setSubmitBusy],
  );

  const handleForcePasswordReset = React.useCallback(
    (event: React.MouseEvent<Button>) => {
      if (
        account &&
        // eslint-disable-next-line no-restricted-globals
        confirm(
          'Are you sure you want to deactivate this account and send a password reset email',
        )
      ) {
        setSubmitBusy(true);
        patchAccount({
          recnum: account?.recnum,
          force_password_reset: true,
          action_url: window.location.origin + '/recover',
          bcc: store.getState().user.email,
        }).finally(() => {
          setSubmitBusy(false);
        });
      }
    },
    [account, patchAccount, store, setSubmitBusy],
  );

  const accountPermissions = decode(account?.permissions || 0);

  if (account || err) {
    content = (
      <>
        {err && (
          <Alert bsStyle="danger">Could not load account: {err.message}</Alert>
        )}
        {patchErr && (
          <Alert bsStyle="danger">
            Could not patch account: {patchErr.message}
          </Alert>
        )}
        {account?.requested_authentication_tag && (
          <Alert>
            <p>
              Tag-authenticatie verzocht sinds{' '}
              <FormattedRelative
                value={account.requested_authentication_tag_date!}
              />
            </p>
            <p />
            <Button
              onClick={() => handleTagAuthRequest(true)}
              bsStyle="success"
            >
              activeren
            </Button>{' '}
            <Button
              onClick={() => handleTagAuthRequest(false)}
              bsStyle="danger"
            >
              weigeren
            </Button>
          </Alert>
        )}
        {patchSuccess && <Alert bsStyle="success">Success!</Alert>}

        {account && (
          <form onSubmit={handleSubmit}>
            <Checkbox
              disabled={!account.loginname.trim()}
              inputRef={activatedRef as any}
              defaultChecked={account.activated}
            >
              Activated
            </Checkbox>
            <FieldGroup
              id="loginname"
              type="text"
              label="loginname"
              placeholder="Loginname"
              defaultValue={account.loginname}
              help={`Conforms to ${loginnameValidator.toString()}`}
              disabled
            />
            <FieldGroup
              id="name"
              type="text"
              label="Full name"
              placeholder="John doe"
              inputRef={nameRef}
              defaultValue={account.name}
            />
            <FieldGroup
              id="email"
              type="text"
              label="Email"
              defaultValue={account.email}
              disabled
            />
            <FieldGroup
              id="department"
              type="text"
              label="Department"
              defaultValue={account.department}
              disabled
            />
            <FieldGroup
              id="note"
              componentClass="textarea"
              label="Notes"
              inputRef={noteRef}
              defaultValue={account.note}
            />
            <hr />
            <h4>Permissions</h4>
            {PERMISSIONS.map((perm, idx) => (
              <Checkbox
                key={perm}
                disabled={
                  account.internal === permissionIsExternal[perm] ||
                  perm === 'QA_SUPER_USER'
                }
                inputRef={permissionRefs[idx] as any}
                defaultChecked={accountPermissions[perm]}
              >
                {perm}
              </Checkbox>
            ))}
            <hr />
            <h4>Security</h4>
            <dl className="dl dl-horizontal">
              <dt>Last successful login</dt>
              <dd>
                {account.last_successful_login ? (
                  <FormattedRelative value={account.last_successful_login} />
                ) : (
                  ' - '
                )}
              </dd>
              <dt>password strength</dt>
              <dd>{account.password_strength}</dd>
              <dt>password date</dt>
              <dd>
                {account.password_date ? (
                  <FormattedRelative
                    value={account.password_date}
                  ></FormattedRelative>
                ) : (
                  ' - '
                )}
              </dd>
              <dt>Failed auth attempts</dt>
              <dd>{account.auth_attempts}</dd>
            </dl>
            <hr />
            <h4>Tag Authenticatie</h4>
            <dl className="dl dl-horizontal">
              <dt>Ingeschakeld</dt>
              <dd>
                <BooleanField value={account.authentication_tag_enabled} />
              </dd>
              <dt>Verzoek openstaand</dt>
              <dd>
                <BooleanField value={account.requested_authentication_tag} />
              </dd>
              <dt>Verzoek ingedient</dt>
              <dd>
                {(account.requested_authentication_tag_date && (
                  <FormattedRelative
                    value={account.requested_authentication_tag_date!}
                  />
                )) ||
                  '-'}
              </dd>
            </dl>
            <h4>Clients</h4>
            <table className="table table-condensed">
              <thead>
                <tr>
                  <td>Identifier</td>
                  <td>browser</td>
                  <td>version</td>
                  <td>sync status</td>
                  <td>last contact</td>
                  <td>created</td>
                </tr>
              </thead>
              <tbody>
                {account.clientInfo?.map(info => (
                  <tr key={info.client_id}>
                    <td>
                      <samp title={info.client_id}>
                        {info.client_id.slice(0, 8)}
                      </samp>
                    </td>
                    <td>
                      <ReadableUseragentString>
                        {info.useragent}
                      </ReadableUseragentString>
                    </td>
                    <td>{info.version}</td>
                    <td>
                      <BooleanField
                        title={JSON.stringify(info.meta, null, 2)}
                        value={info.success}
                        positiveMsg="success"
                        negativeMsg="failure"
                      />
                    </td>
                    <td>
                      <FormattedRelative value={info.last_contact} />
                    </td>
                    <td>
                      <FormattedRelative value={info.created} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr />
            <div className="row">
              <>
                {/* save & account actions */}
                <Button disabled={submitBusy} type="submit" bsStyle="primary">
                  Save
                </Button>{' '}
                {submitBusy && <img alt="progress indicator" src={loader} />}
                <div className="pull-right">
                  <div className="pull-right">
                    <DropdownButton
                      dropup
                      pullRight
                      title="Account Actions"
                      id="account-actions-dropdown"
                      dir="left"
                    >
                      <MenuItem
                        eventKey="send-invitation"
                        disabled={
                          submitBusy || !account || !!account.loginname.trim()
                        }
                        onClick={handleSendInvitation}
                      >
                        Send invitation
                      </MenuItem>
                      <MenuItem divider />

                      <MenuItem
                        eventKey="force-reactivation"
                        disabled={
                          submitBusy || !account || !account.loginname.trim()
                        }
                        onClick={handleForceReactivation}
                      >
                        Force reactivation
                      </MenuItem>
                      <MenuItem
                        eventKey="force-password-reset"
                        disabled={
                          submitBusy || !account || !account.loginname.trim()
                        }
                        onClick={handleForcePasswordReset}
                      >
                        Force password reset
                      </MenuItem>
                      <MenuItem
                        eventKey="reset-auth-attempts"
                        disabled={
                          submitBusy || !account || !account.loginname.trim()
                        }
                        onClick={handleResetAuthAttempts}
                      >
                        Reset auth attempts
                      </MenuItem>
                      <MenuItem divider />

                      <MenuItem
                        eventKey="disable-tag-auth"
                        disabled={
                          submitBusy ||
                          !account ||
                          !account.authentication_tag_enabled
                        }
                        onClick={() => {
                          patchAccount({
                            recnum: account!.recnum,
                            set_authentication_tag_id: null,
                          });
                        }}
                      >
                        Tag-authenticatie uitschakelen en wissen
                      </MenuItem>
                    </DropdownButton>
                  </div>
                </div>
              </>
            </div>
          </form>
        )}
      </>
    );
  }
  return (
    <Page title="Accounts">
      {content}
      {/* <Json>{{ account }}</Json> */}
    </Page>
  );
};

export default AccountDetailsComponent;

interface BooleanFieldProps {
  value: boolean;
  positiveMsg?: string;
  negativeMsg?: string;
  title?: string;
}
const BooleanField: React.FC<BooleanFieldProps> = ({
  value,
  positiveMsg = 'ja',
  negativeMsg = 'nee',
  title,
}) => {
  return (
    <span title={title} className={value ? 'text-success' : 'text-danger'}>
      {value ? `✔ ${positiveMsg}` : `✗ ${negativeMsg}`}
    </span>
  );
};
