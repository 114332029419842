import { useEffect, useState } from 'react';

/**
 * Custom hook to read NFC tag serial number
 * @param onSerialNumberRead Callback function to handle the read serial number
 * @param shouldRead Boolean to control whether reading should start
 * @returns Object containing reading status and error state
 */
const useNFCReader = (
  onSerialNumberRead: (serialNumber: string) => void,
  shouldRead: boolean,
) => {
  const [isReading, setIsReading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // console.log('call', { onSerialNumberRead, shouldRead });

  useEffect(() => {
    // console.log('effect', { shouldRead });

    if (!shouldRead) {
      return;
    }

    if (!('NDEFReader' in window)) {
      setError('NFC is not supported in this browser');
      setIsReading(false);
      return;
    }

    const nfcReader = new NDEFReader();
    const abortController = new AbortController();

    const startReading = async () => {
      console.log('start');

      try {
        setError(null);
        setIsReading(true);
        await nfcReader.scan({ signal: abortController.signal });
        console.log('start2');

        nfcReader.onreading = (event: NDEFReadingEvent) => {
          const serialNumber = event.serialNumber;
          onSerialNumberRead(serialNumber);
        };

        nfcReader.onreadingerror = () => {
          setError('Error reading NFC tag');
        };
      } catch (err) {
        console.log({ err });

        setError(err instanceof Error ? err.message : 'Unknown NFC error');
      } finally {
        // setIsReading(false);
      }
    };

    startReading();

    return () => {
      console.log('cleanup');
      abortController.abort();
      setIsReading(false);
    };
  }, [onSerialNumberRead, shouldRead]);

  return { isReading, error };
};

export default useNFCReader;
