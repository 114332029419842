import { v4 as uuid } from 'uuid';

const clientIdentifierKey = 'certhon_app_client_identifier';

let identifier = '';

export function getClientIdentifier() {
  if (!identifier) {
    identifier = localStorage.getItem(clientIdentifierKey) || '';
    if (!identifier) {
      identifier = uuid();
      localStorage.setItem(clientIdentifierKey, identifier);
    }
  }
  return identifier;
}

const tagAuthKey = 'certhon_app_tag_auth_enabled';

let tagAuthEnabled: boolean | null = null;

export function getTagAuthEnabled(): boolean {
  if (tagAuthEnabled === null) {
    tagAuthEnabled = JSON.parse(
      localStorage.getItem(tagAuthKey) || 'false',
    ) as boolean;
  }
  return tagAuthEnabled;
}

export function setTagAuthEnabled(enabled: boolean) {
  tagAuthEnabled = enabled;
  localStorage.setItem(tagAuthKey, JSON.stringify(enabled));
}
