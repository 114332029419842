import { useCallback, useEffect, useReducer, useState } from 'react';
import { useStore } from 'react-redux';
import { resolveHeaders, resolveUrl } from '../utils';

export function performApiCall<T>(
  state: any,
  input: string, //RequestInfo,
  init?: RequestInit,
): Promise<T> {
  return fetch(resolveUrl(input), {
    ...init,
    headers: resolveHeaders(init?.headers)(state),
  }).then(async res => {
    if (res.status >= 300) {
      const json = await res.json();
      const err: any = new Error(json.error);
      err.json = json;
      err.res = res;
      throw err;
    }
    return await res.json();
  });
}

export function useApiCall() {
  const store = useStore();
  const apiCall = useCallback(
    <T,>(input: string, init?: RequestInit) => {
      return performApiCall<T>(store.getState(), input, init);
    },
    [store],
  );
  return apiCall;
}

/**
 * whatwg-fetch compatible method to perform api call to backend
 * it automatically resolves the backend url and set authorization & JSON headers
 * @param input
 * @param init
 */
export function useInitApiCall<T>(
  input: string,
  init?: RequestInit,
): [T | null, Error | null, () => void] {
  const [i, refresh] = useReducer(i => i + 1, 0);
  const [result, setResult] = useState<T | null>(null);
  const [err, setErr] = useState<Error | null>(null);
  const apiCall = useApiCall();
  useEffect(() => {
    apiCall<T>(input, init)
      .then(setResult)
      .catch(setErr);
  }, [setResult, setErr, apiCall, input, init, i]);
  return [result, err, refresh];
}
