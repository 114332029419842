import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import styles from './PinCodeEntry.module.scss';

// Define the ref interface
export interface PinCodeEntryRef {
  getPinCode: (twice?: boolean) => Promise<string>;
}

const PinCodeEntry = forwardRef<PinCodeEntryRef>((props, ref) => {
  const [show, setShow] = useState(false);
  const [pin, setPin] = useState('');
  const [secondPin, setSecondPin] = useState('');
  const [requiresTwice, setRequiresTwice] = useState(false);
  const [isSecondEntry, setIsSecondEntry] = useState(false);
  const [pinsDoNotMatchWarning, setPinsDoNotMatchWarning] = React.useState<
    boolean
  >(false);

  const [resolve, setResolve] = React.useState<
    ((value: string) => void) | null
  >(null);
  const [reject, setReject] = React.useState<((value: Error) => void) | null>(
    null,
  );

  // Expose getPinCode through ref
  useImperativeHandle(ref, () => ({
    getPinCode: (twice: boolean = false) => {
      return new Promise((resolve, reject) => {
        setRequiresTwice(twice);
        setPin('');
        setSecondPin('');
        setIsSecondEntry(false);
        setPinsDoNotMatchWarning(false);
        setShow(true);
        setResolve(() => resolve);
        setReject(() => reject);
      });
    },
  }));

  const handleNumberClick = (num: string) => {
    if (isSecondEntry) {
      if (secondPin.length < 4) {
        setSecondPin(secondPin + num);
      }
    } else {
      if (pin.length < 4) {
        setPin(pin + num);
      }
    }
  };

  const handleClear = () => {
    if (isSecondEntry) {
      setSecondPin('');
    } else {
      setPin('');
    }
  };

  const handleCancel = () => {
    setShow(false);
    reject?.(new Error('User cancelled'));
  };

  const handleSubmit = () => {
    if (pin.length === 4) {
      if (requiresTwice) {
        if (!isSecondEntry) {
          setIsSecondEntry(true);
          setPinsDoNotMatchWarning(false);
        } else {
          if (pin === secondPin) {
            resolve?.(pin);
            setShow(false);
          } else {
            // Pins don't match, reset second entry
            setPinsDoNotMatchWarning(true);
            setSecondPin('');
            setPin('');
            setIsSecondEntry(false);
          }
        }
      } else {
        resolve?.(pin);
        setShow(false);
      }
    }
  };

  const currentPin = isSecondEntry ? secondPin : pin;

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      // centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          {requiresTwice && isSecondEntry
            ? 'Confirm PIN Code'
            : 'Enter PIN Code'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {pinsDoNotMatchWarning && (
          <Alert bsStyle="danger">
            The codes do not match, please try again
          </Alert>
        )}
        <div className={styles.pinDisplay}>
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <div
                key={i}
                className={`${styles.pinDot} ${
                  i < currentPin.length ? styles.filled : ''
                }`}
              />
            ))}
        </div>
        <div className={styles.numpad}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0, 'clear'].map(num => (
            <Button
              key={num}
              className={styles.numButton}
              onClick={() =>
                num === 'C'
                  ? handleClear()
                  : num !== '' && handleNumberClick(num.toString())
              }
              disabled={num === ''}
            >
              {num}
            </Button>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          // bsStyle=""
          onClick={handleCancel}
          // disabled={currentPin.length !== 4}
        >
          cancel
        </Button>
        <Button
          bsStyle="primary"
          onClick={handleSubmit}
          disabled={currentPin.length !== 4}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default PinCodeEntry;
