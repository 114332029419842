import PropTypes from 'prop-types';
import * as React from 'react';
import { OnlineService, SyncService } from '.';
import ListBuilder from '../common/list/listBuilder';
import { BarcodeScannerProvider } from './BarcodeScanner/BarcodeScannerContext';
import UpdateService from './UpdateService';

interface Props {
  syncService: SyncService;
  onlineService: OnlineService;
  listBuilder: ListBuilder;
  updateService: UpdateService;
  children: React.ReactNode;
}

export const SyncServiceContext = React.createContext<SyncService | null>(null);
export const OnlineServiceContext = React.createContext<OnlineService | null>(
  null,
);
export const ListBuilderContext = React.createContext<ListBuilder | null>(null);
export const UpdateServiceContext = React.createContext<UpdateService | null>(
  null,
);

export default class ServiceProvider extends React.Component<Props> {
  static propTypes = {
    syncService: PropTypes.object.isRequired,
  };
  static childContextTypes = {
    syncService: PropTypes.object.isRequired,
    onlineService: PropTypes.object.isRequired,
    listBuilder: PropTypes.object.isRequired,
    updateService: PropTypes.object.isRequired,
  };
  getChildContext() {
    const { children, ...rest } = this.props;
    return rest;
  }
  render() {
    const { children } = this.props;
    return (
      <SyncServiceContext.Provider value={this.props.syncService}>
        <ListBuilderContext.Provider value={this.props.listBuilder}>
          <OnlineServiceContext.Provider value={this.props.onlineService}>
            <UpdateServiceContext.Provider value={this.props.updateService}>
              <BarcodeScannerProvider>{children}</BarcodeScannerProvider>
            </UpdateServiceContext.Provider>
          </OnlineServiceContext.Provider>
        </ListBuilderContext.Provider>
      </SyncServiceContext.Provider>
    );
  }
}

export function useSyncService() {
  return React.useContext(SyncServiceContext)!;
}
export function useListBuilder() {
  return React.useContext(ListBuilderContext);
}
export function useOnlineService() {
  return React.useContext(OnlineServiceContext);
}
export function useUpdateService() {
  const updateService = React.useContext(UpdateServiceContext);
  // we use this to trigger a rerender
  const [, setAvailableVersion] = React.useState<string | null>(null);
  const handleUpdateAvailable = React.useCallback(() => {
    setAvailableVersion(updateService?.availableVersion || null);
  }, [updateService?.availableVersion]);
  React.useEffect(() => {
    if (updateService) {
      updateService.addEventListener('updateAvailable', handleUpdateAvailable);
      return () =>
        updateService.removeEventListener(
          'updateAvailable',
          handleUpdateAvailable,
        );
    }
  }, [handleUpdateAvailable, updateService]);
  return updateService;
}
