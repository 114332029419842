// tslint:disable ordered-imports
import * as Sentry from '@sentry/react';
import bytes from 'bytes';
import qs from 'query-string';
import * as R from 'ramda';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import ListBuilder from './common/list/listBuilder';
import './errorReporting';
import { setDefaultProfile } from './modules/attachments';
import { fetchUser, fetchUserAndSetAccessToken } from './modules/user';
import { OnlineService, SyncService } from './services';
import ServiceProvider from './services/ServiceProvider';
import { TokenService } from './services/TokenService';
import UpdateService, { isGreaterVersion } from './services/UpdateService';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import configureStore from './store/configureStore';
import { createReduxHistory } from './store/routerContext';
import { resolveUrl, setApiBackend } from './utils';
import ReportReadyProvider from './utils/ReportReady/ReportReadyProvider';
// These should be in this order to have correct styling!!
/* do  */
import './style.scss';
/* not */
import Root from './Root';
/* reorder */

if (process.env.NODE_ENV !== 'production') {
  (window as any).R = R;
  (window as any).bytes = bytes;
}

// (React as any).StrictMode = React.Fragment;

const root = createRoot(document.getElementById('root')!);

const params = qs.parse(window.location.search);
Sentry.setExtra('reportMode', !!params.reportmode);

if (params.reportmode) {
  /*
   * Configure the client to run in reportmode
   * 1. exlicitly set attachement size
   * 2. Configure alternative host:port for api-backend
   * 3. expose renderReport function to render a url using a accessToken and resolve promise when everything is loaded
   * 4. dont use serviceworker
   */
  document.body.classList.add('print'); //fixme: proper solution
  setDefaultProfile('medium'); // 1

  if (params.apiBackend && !(params.apiBackend instanceof Array)) {
    setApiBackend(params.apiBackend); // 2
  }

  // 3
  (window as any).renderReport = async (path: string, token: string) => {
    const { store } = configureStore({ tmp: true });
    const history = createReduxHistory(store);
    await store.dispatch(fetchUserAndSetAccessToken(token));
    const { user } = store.getState();
    Sentry.setUser({
      id: user.recnum,
    });
    history.replace(path);
    return new Promise<void>(resolve =>
      root.render(
        // <React.StrictMode> // fixme: strict mode breaks ReportReadyProvider
        <ReportReadyProvider
          onReady={() => {
            console.log('ready!');
            resolve();
          }}
        >
          <Root store={store} history={history} />
        </ReportReadyProvider>,
        // </React.StrictMode>,
      ),
    );
  };

  serviceWorkerRegistration.unregister(); // 4
} else {
  const updateService = new UpdateService();

  if (
    isGreaterVersion(
      updateService.currentVersion,
      localStorage.getItem('client-version') || 'v0',
    )
  ) {
    // update current version
    localStorage.setItem('client-version', updateService.currentVersion);
  }

  /*
   * Configure client to run in normal mode
   */
  const { store, persistor } = configureStore({ tmp: false });
  const history = createReduxHistory(store);

  if (!persistor) {
    throw new Error('Missing persistor');
  }

  // services
  const tokenService = new TokenService(store);
  const onlineService = new OnlineService(store);
  const syncService = new SyncService(store, updateService);
  const listBuilder = new ListBuilder(store);

  (window as any).certhon = {
    syncService,
    onlineService,
    listBuilder,
    updateService,
    store,
    persistor,
    accessTokenService: tokenService,
  };

  const setUserContext = () => {
    const { user } = store.getState();
    if (user && user.recnum) {
      Sentry.setUser({
        id: user.recnum,
      });
    }
  };

  root.render(
    //<React.StrictMode>
    <PersistGate
      loading={null}
      persistor={persistor}
      onBeforeLift={setUserContext}
    >
      <ServiceProvider
        syncService={syncService}
        onlineService={onlineService}
        listBuilder={listBuilder}
        updateService={updateService}
      >
        <Root store={store} history={history} />
      </ServiceProvider>
    </PersistGate>,
    //</React.StrictMode>,
  );

  serviceWorkerRegistration.register({
    onSuccess: reg => console.log(reg),
    onUpdate: reg => {
      console.log('update', reg);
      updateService.handleServicePossibleWorkerUpdate(reg);
    },
  });
}
